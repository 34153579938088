import { isWebConfig } from './lib/utils';
import Dashboard from './screens/dashboard';
import Loyalty from './screens/loyalty';
import Vouchers from './screens/vouchers';
import Account from './screens/account';
import ReferAFriend from './screens/referAFriend';
import Feedback from './screens/feedback';
import History from './screens/history';
import HistoryDetails from './screens/historyDetails';
import Locations from './screens/locations';
import Social from './screens/social';
import Login from './screens/login';
import Register from './screens/register';
import ResetPassword from './screens/resetPassword';
import Terms from './screens/terms';
import Privacy from './screens/privacy';
import Faq from './screens/faq';
import ItemDetails from './screens/itemDetails';
import DeliveryOptions from './screens/delivery-options';
import Delivery from './screens/delivery';
import DeliveryAddressCheck from './screens/delivery-address-check';
import DeliveryAddressAdd from './screens/delivery-address-add';
import DeliveryTime from './screens/delivery-time';
import PickUpPoint from './screens/pick-up-point';
import PickUpPointCheck from './screens/pick-up-point-check';
import ClickAndCollect from './screens/clickAndCollect';
import Cards from './screens/cards';
import CardAdd from './screens/cardAdd';
import OrderSummary from './screens/orderSummary';
import ApplyPoints from './screens/applyPoints';
import Checkout from './screens/checkout';
import OrderCompleted from './screens/orderCompleted';
import ContactDetails from './screens/contactDetails';
import OrderToTable from './screens/orderToTable';
import ApplyVouchers from './screens/applyVouchers';
import { getConfig } from './appConfig';
import { getSingleDeliveryOption } from './lib/utils';

import homeIcon from './assets/images/icons/home.svg';
import loyaltyIcon from './assets/images/icons/loyalty.svg';
import myAccountIcon from './assets/images/icons/my-account.svg';
import feedbackIcon from './assets/images/icons/feedback.svg';
import historyIcon from './assets/images/icons/history.svg';
import locationsIcon from './assets/images/icons/locations.svg';
import socialIcon from './assets/images/icons/social.svg';
import loginIcon from './assets/images/icons/login.svg';
import logoutIcon from './assets/images/icons/logout.svg';
import settingsIcon from './assets/images/icons/settings.svg';
import referAFriendIcon from './assets/images/icons/refer-a-friend.svg';
import voucherIcon from './assets/images/icons/vouchers.svg';
import termsIcon from './assets/images/icons/terms.svg';
import privacyIcon from './assets/images/icons/privacy.svg';
import faqIcon from './assets/images/icons/faq.svg';
import menuIcon from './assets/images/icons/menu.svg';

const Order = require('./screens/order' + (isWebConfig() ? 'Web' : '')).default;
const { appType } = getConfig();
const { hasCampaignManager, hasOrdering, hasLoyalty } = appType;

const singleDeliveryOption = getSingleDeliveryOption();

const navConfig = {
  routes: [
    {
      label: 'Home',
      path: '/dashboard',
      icon: homeIcon,
      component: Dashboard,
      exact: true,
      default: !isWebConfig(),
      notInDrawer: isWebConfig(),
    },
    {
      label: 'Menu',
      path: '/order',
      component: Order,
      icon: menuIcon,
      exact: true,
      notInDrawer: !isWebConfig(),
    },
    ...(hasOrdering && [
      {
        label: singleDeliveryOption ? singleDeliveryOption.label : '',
        path: '/click-and-collect',
        component: ClickAndCollect,
        protected: false,
        notInDrawer: true,
      },
      {
        label: singleDeliveryOption ? singleDeliveryOption.label : '',
        path: '/order-to-table',
        component: OrderToTable,
        protected: false,
        notInDrawer: true,
      },
      {
        label: 'Start new order',
        component: DeliveryOptions,
        icon: settingsIcon,
        path: '/delivery-options',
        default: isWebConfig(),
        protected: false,
      },
    ]),
    ...(hasLoyalty
      ? [
          {
            label: 'Loyalty',
            path: '/loyalty',
            component: Loyalty,
            icon: loyaltyIcon,
            protected: true,
            state: { tab: 'points' },
          },
        ]
      : []),
    ...(hasCampaignManager
      ? [
          {
            label: 'Vouchers',
            path: '/vouchers',
            component: Vouchers,
            icon: voucherIcon,
            protected: true,
          },
        ]
      : []),
    ...(hasCampaignManager
      ? [
          {
            label: 'Refer a Friend',
            path: '/refer-a-friend',
            component: ReferAFriend,
            icon: referAFriendIcon,
            protected: true,
          },
        ]
      : []),
    {
      label: 'My Account',
      path: '/account',
      component: Account,
      icon: myAccountIcon,
      protected: true,
    },
    ...(hasOrdering
      ? [
          {
            label: 'Checkout',
            path: '/checkout',
            component: Checkout,
            icon: settingsIcon,
            notInDrawer: true,
            protected: true,
          },
        ]
      : []),
    ...(hasOrdering
      ? [
          {
            label: 'Apply Points',
            path: '/apply-points',
            component: ApplyPoints,
            icon: settingsIcon,
            notInDrawer: true,
            protected: true,
          },
        ]
      : []),
    { label: 'History', path: '/history', component: History, icon: historyIcon, protected: true },
    {
      label: 'Leave Feedback',
      path: '/feedback',
      component: Feedback,
      icon: feedbackIcon,
      protected: true,
    },
    {
      label: 'Locations',
      path: '/locations',
      component: Locations,
      icon: locationsIcon,
      protected: false,
    },
    {
      label: 'Social Media',
      path: '/social',
      component: Social,
      icon: socialIcon,
      protected: false,
    },
    {
      label: 'Contact Details',
      path: '/contact-details',
      component: ContactDetails,
      protected: true,
      notInDrawer: true,
    },
    {
      label: 'Apply Vouchers',
      path: '/apply-vouchers',
      component: ApplyVouchers,
      notInDrawer: true,
      protected: true,
    },
  ],
  authRoutes: [
    { label: 'Login', path: '/login', component: Login, icon: loginIcon, fn: 'login' },
    { label: 'Logout', path: '/logout', icon: logoutIcon, fn: 'logout' },
    { path: '/contact-details', component: ContactDetails },
  ],
  additionalRoutes: [
    { label: 'Terms & Conditions', path: '/terms', component: Terms, icon: termsIcon },
    { label: 'Privacy Policy', path: '/privacy', component: Privacy, icon: privacyIcon },
    { label: 'Faq & Support', path: '/faq', component: Faq, icon: faqIcon },
  ],
  notInMenuRoutes: [
    { path: '/register', component: Register },
    { path: '/reset-password', component: ResetPassword },
    ...(hasOrdering ? [{ path: '/item-details', component: ItemDetails }] : []),
    ...(hasOrdering ? [{ path: '/order', component: Order }] : []),
    ...(hasOrdering ? [{ path: '/cards', component: Cards }] : []),
    ...(hasOrdering ? [{ path: '/card-add', component: CardAdd }] : []),
    ...(hasOrdering ? [{ path: '/history-details', component: HistoryDetails }] : []),
    ...(hasOrdering ? [{ path: '/order-summary', component: OrderSummary }] : []),
    ...(hasOrdering ? [{ path: '/order-completed', component: OrderCompleted }] : []),
    ...(hasOrdering ? [{ path: '/delivery', component: Delivery }] : []),
    ...(hasOrdering ? [{ path: '/delivery-address-check', component: DeliveryAddressCheck }] : []),
    ...(hasOrdering ? [{ path: '/delivery-address-add', component: DeliveryAddressAdd }] : []),
    ...(hasOrdering ? [{ path: '/delivery-time', component: DeliveryTime }] : []),
    ...(hasOrdering ? [{ path: '/pick-up-point', component: PickUpPoint }] : []),
    ...(hasOrdering ? [{ path: '/pick-up-point-check', component: PickUpPointCheck }] : []),
    ...(hasOrdering ? [{ path: '/order-to-table', component: OrderToTable }] : []),
  ],
};

export default navConfig;
