import React from 'react';
import moment from '../../lib/moment';
import { IonItem, IonRow, IonCol, IonGrid, IonButton, IonAlert } from '@ionic/react';
import Loading from '../../components/spinner';
import { withTranslation } from '../../lib/translate';
import Layout from '../../components/layout';
import { Title, SmallText, Spacer, NormalText } from '../../components/common';
import Box, { BoxHeader } from '../../components/box';
import { connect } from 'react-redux';
import { createNewBasketInstance } from '../../lib/basket';
import { forwardTo, visaEndingDrawText } from '../../lib/utils';
import OrderContent from '../../components/orderContent';
import Basket from '../../lib/basket';
import AmountPaid from '../../components/amountPaid';
import { withRouter } from 'react-router';
import { getConfig } from '../../appConfig';
import { checkCancelOrder, setOrdersProp } from '../../store/orders/actions';
import './index.css';

class HistoryDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCancel: false,
      orderId: null,
      restaurantId: null,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const orderArr = location?.state?.order;
    const now = moment();
    let cutoffTime = orderArr ? orderArr.cutoff_time : now;
    let cutoffTimeMoment = new moment(cutoffTime, 'YYYY-MM-DD HH:mm:ss');
    const utcOffset = cutoffTimeMoment.utcOffset();
    cutoffTimeMoment = cutoffTimeMoment.add('minutes', utcOffset);
    if (now.isBefore(cutoffTimeMoment)) {
      if (orderArr.status !== 'REFUNDED') {
        this.setState({
          isCancel: true,
          orderId: orderArr.id,
          restaurantId: orderArr.restaurant_id,
        });
      }
    }
  }

  drawContentTitle = (__, deliveryOption, id, orderType) => {
    if (orderType === 'Outpost Drop-Off') {
      return (
        <Title>
          {__('Drop Point')} {__('Order')} #{id}
        </Title>
      );
    } else if (orderType === 'Delivery') {
      return (
        <Title>
          <strong>
            {__('Delivery')} {__('Order')} #{id}
          </strong>
        </Title>
      );
    } else if (orderType === 'Table') {
      return (
        <Title>
          <strong>
            {__('At Table')} {__('Order')} #{id}
          </strong>
        </Title>
      );
    } else {
      return (
        <Title>
          {__(orderType)} {__('Order')} #{id}
        </Title>
      );
    }
  };

  backHandler = () => {
    this.setState({ orderId: null, isCancel: false, restaurantId: null });
    forwardTo('/dashboard');
    forwardTo('/history', { tab: 'order' });
  };

  formatPaymentString = (str) => {
    let splitedStr = str.split(' ').splice(2).join(' ');
    return splitedStr;
  };

  cancelOrder = () => {
    const { orderId, isCancel, restaurantId } = this.state;
    if (isCancel) {
      this.props.dispatch(checkCancelOrder(orderId, restaurantId));
      this.setState({ orderId: null, isCancel: false, restaurantId: null });
    }
  };

  handleCancelOrderModal = (flag) => {
    this.props.dispatch(setOrdersProp('cancelOrderModal', flag));
  };

  drawContent = (__, order, orderType, basketInstance, orderCompletePage) => {
    const isOldLayoutOfConfirmationScreens = getConfig().appType.oldLayoutConfirmationScreens;
    if (orderType === 'Click & Collect' || orderType === 'table') {
      return (
        <>
          <IonRow className={`${isOldLayoutOfConfirmationScreens ? 'inline-block' : ''}`}>
            <IonCol size="5" className="paddLR">
              <SmallText tag="strong" className="bold mr-5">
                {__('Collect from')}:
              </SmallText>
            </IonCol>
            <IonCol className="righted">
              <SmallText color="primary" className="thiner-text">
                {order.restaurant_name}
              </SmallText>
            </IonCol>
          </IonRow>
          <IonRow className={`${isOldLayoutOfConfirmationScreens ? 'inline-block' : ''}`}>
            <IonCol size="5" className="paddLR">
              <SmallText tag="strong" className="bold mr-5">
                {__('Collect at')}:
              </SmallText>
            </IonCol>
            <IonCol className="righted">
              <SmallText color="primary" className="thiner-text">
                {basketInstance.formatOrderTime(true, 'DD MMM YYYY [at] h:mm a')}
              </SmallText>
            </IonCol>
          </IonRow>
          <IonRow className={`${isOldLayoutOfConfirmationScreens ? 'inline-block' : ''}`}>
            <IonCol size="5" className="paddLR">
              <SmallText tag="strong" className="bold mr-5">
                {__('Order placed on')}:
              </SmallText>
            </IonCol>
            <IonCol className="righted">
              <SmallText color="primary" className="thiner-text">
                {moment(order.created_at).format(
                  /* 'ddd DD MMMM YYYY [at] LT' */ 'DD MMM YYYY [at] h:mm a',
                )}
              </SmallText>
            </IonCol>
          </IonRow>
          {!isOldLayoutOfConfirmationScreens ? (
            <IonRow>
              <IonCol size="5" className="paddLR">
                <SmallText tag="strong" className="bold mr-5">
                  {__('Payment Method')}:
                </SmallText>
              </IonCol>
              <IonCol className="righted">
                <SmallText color="primary" className="thiner-text">
                  {visaEndingDrawText(
                    basketInstance.formatPaymentMethod(this.props.cards, __, orderCompletePage),
                  )}
                </SmallText>
              </IonCol>
            </IonRow>
          ) : null}
        </>
      );
    } else if (orderType === 'Delivery') {
      return (
        <>
          <IonRow className={`${isOldLayoutOfConfirmationScreens ? 'inline-block' : ''}`}>
            <IonCol size="5" className="paddLR">
              <SmallText tag="strong" className="bold">
                {__('Delivery from')}:
              </SmallText>
            </IonCol>
            <IonCol className="righted">
              <SmallText color="primary" className="thiner-text">
                {order.delivery_address.addressLine1}, {order.delivery_address.place},{' '}
                {order.delivery_address.postalCode}
              </SmallText>
            </IonCol>
          </IonRow>
          <IonRow className={`${isOldLayoutOfConfirmationScreens ? 'inline-block' : ''}`}>
            <IonCol size="5" className="paddLR">
              <SmallText tag="strong" className="bold">
                {__('Order placed on')}:
              </SmallText>
            </IonCol>
            <IonCol className="righted">
              <SmallText color="primary" className="thiner-text">
                {moment(order.created_at).format('D MMM YYYY [at] h:mm a')}
              </SmallText>
            </IonCol>
          </IonRow>
          <IonRow className={`${isOldLayoutOfConfirmationScreens ? 'inline-block' : ''}`}>
            <IonCol size="5" className="paddLR">
              <SmallText tag="strong" className="bold">
                {__('Delivery at')}:
              </SmallText>
            </IonCol>
            <IonCol className="righted">
              <SmallText color="primary" className="thiner-text">
                {basketInstance.formatOrderTime(true)}
              </SmallText>
            </IonCol>
          </IonRow>
          {
            /*order && order.order_value > 0 ||*/ orderType !== 'Click & Collect' ? (
              <IonRow className={`${isOldLayoutOfConfirmationScreens ? 'inline-block' : ''}`}>
                <IonCol size="5" className="paddLR">
                  <SmallText tag="strong" className="bold">
                    {__('Payment Method')}:
                  </SmallText>
                </IonCol>
                <IonCol className="righted">
                  <SmallText color="primary" className="thiner-text">
                    {visaEndingDrawText(
                      basketInstance.formatPaymentMethod(this.props.cards, __, orderCompletePage),
                    )}
                  </SmallText>
                </IonCol>
              </IonRow>
            ) : null
          }
        </>
      );
    } else if (orderType === 'Outpost Drop-Off') {
      return (
        <>
          <IonRow>
            <IonCol size="5" className="paddLR">
              <SmallText tag="strong" className="bold">
                {__('Drop-off at')}:
              </SmallText>
            </IonCol>
            <IonCol className="righted">
              <SmallText color="primary" className="thiner-text">
                {order.restaurant_name}
              </SmallText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5" className="paddLR">
              <SmallText tag="strong" className="bold">
                {__('Order Placed')}:
              </SmallText>
            </IonCol>
            <IonCol className="righted">
              <SmallText color="primary" className="thiner-text">
                {moment(order.created_at).format('ddd DD MMMM YYYY [at] LT')}
              </SmallText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5" className="paddLR">
              <SmallText tag="strong" className="bold">
                {__('Drop-off Time')}:
              </SmallText>
            </IonCol>
            <IonCol className="righted">
              <SmallText color="primary" className="thiner-text">
                {basketInstance.formatOrderTime(true)}
              </SmallText>
            </IonCol>
          </IonRow>
          {order && order.order_value > 0 ? (
            <IonRow>
              <IonCol size="5" className="paddLR">
                <SmallText tag="strong" className="bold">
                  {__('Payment Method')}:
                </SmallText>
              </IonCol>
              <IonCol className="righted">
                <SmallText color="primary" className="thiner-text">
                  {visaEndingDrawText(
                    basketInstance.formatPaymentMethod(this.props.cards, __, orderCompletePage),
                  )}
                </SmallText>
              </IonCol>
            </IonRow>
          ) : null}
        </>
      );
    } else if (orderType === 'Table') {
      return (
        <>
          <IonRow className={`${isOldLayoutOfConfirmationScreens ? 'inline-block' : ''}`}>
            <IonCol size="5" className="paddLR mr-5">
              <SmallText tag="strong">
                <b>{__('Ordered at')}:</b>
              </SmallText>
            </IonCol>
            <IonCol className="righted self-aligned">
              <SmallText>{order.restaurant_name}</SmallText>
            </IonCol>
          </IonRow>
          <IonRow className={`${isOldLayoutOfConfirmationScreens ? 'block' : ''}`}>
            <IonCol size="5" className="paddLR mr-5">
              <SmallText tag="strong">
                <b>{__('Table number')}:</b>
              </SmallText>
            </IonCol>
            <IonCol className="righted self-aligned">
              <SmallText>{order.table_name}</SmallText>
            </IonCol>
          </IonRow>
          <IonRow className={`${isOldLayoutOfConfirmationScreens ? 'inline-block' : ''}`}>
            <IonCol size="5" className="paddLR mr-5">
              <SmallText tag="strong">
                <b>{__('Order Placed')}:</b>
              </SmallText>
            </IonCol>
            <IonCol className="righted self-aligned">
              <SmallText>{moment(order.created_at).format('D MMM YYYY [at] h:mm a')}</SmallText>
            </IonCol>
          </IonRow>
          <IonRow className={`${isOldLayoutOfConfirmationScreens ? 'inline-block' : ''}`}>
            <IonCol size="5" className="paddLR mr-5">
              <SmallText tag="strong">
                <b>{__('Payment Method')}:</b>
              </SmallText>
            </IonCol>
            <IonCol className="righted self-aligned">
              <SmallText>
                {visaEndingDrawText(
                  basketInstance.formatPaymentMethod(this.props.cards, __, orderCompletePage),
                )}
              </SmallText>
            </IonCol>
          </IonRow>
        </>
      );
    }
  };
  drawOrder = (order, orderCompletePage) => {
    const { __, cards, lastOrder, location } = this.props;
    const { id } = order;
    const basketInstance = createNewBasketInstance();
    basketInstance.recreateOrder(order);
    const orderType = basketInstance.getOrderType();
    const orderTypeTitle =
      basketInstance.getOrderType() === 'Click & Collect'
        ? 'Collection'
        : basketInstance.getOrderType();
    const hasCancelOrder = getConfig().appType.hasCancelOrder;
    let paymentMetod = basketInstance.formatPaymentMethod(this.props.cards, __, orderCompletePage);
    const isOldLayoutOfConfirmationScreens = getConfig().appType.oldLayoutConfirmationScreens;
    return (
      <>
        {!isOldLayoutOfConfirmationScreens ? (
          <>
            <div className="order-status">
              {/* {lastOrder && lastOrder.status ? __(lastOrder.status) : ''} */}
              {location?.state?.order?.status}
            </div>
            <Spacer size={1} />
            {this.drawContentTitle(__, Basket.getDeliveryOption(), id, orderTypeTitle)}
            <Spacer size={2} />
            {/* <Box> */}
            {/* <BoxHeader> */}
            <IonGrid className="okx-box-header-grid history-details-elements">
              <div className="mb-6">
                <NormalText className="bold">{__('Order Details')}</NormalText>
              </div>
              {this.drawContent(__, order, orderType, basketInstance, orderCompletePage)}
            </IonGrid>
            <Spacer size={3} />
            <NormalText className="bold">{__('Items Ordered')}</NormalText>
            <OrderContent basketInstance={basketInstance} type="orderHistory" />
            <AmountPaid order={order} cards={cards} />
            {this.state.isCancel &&
            ['PAID', 'PARSED', 'NEW', 'new'].indexOf(order.status) !== -1 &&
            hasCancelOrder ? (
              <IonButton
                expand="block"
                fill="clear"
                className="link underlined"
                color="tertiary"
                onClick={() => this.handleCancelOrderModal(true)}
              >
                {__('Cancel this order')}
              </IonButton>
            ) : null}
          </>
        ) : (
          <>
            <div className="order-status">
              {lastOrder && lastOrder.status ? __(lastOrder.status) : ''}
            </div>
            {this.drawContentTitle(__, Basket.getDeliveryOption(), id, orderTypeTitle)}
            {isOldLayoutOfConfirmationScreens ? (
              <div className="order-status-payment-method">
                <IonCol size="5" className="mr-10">
                  <SmallText tag="strong" className="bold">
                    {__('Payment Method')}:
                  </SmallText>
                </IonCol>
                <IonCol>
                  <SmallText color="primary" className="thiner-text">
                    {visaEndingDrawText(paymentMetod)}
                  </SmallText>
                </IonCol>
              </div>
            ) : null}
            <Spacer size={1} />
            <Box>
              <BoxHeader>
                {this.drawContent(__, order, orderType, basketInstance, orderCompletePage)}
              </BoxHeader>
              <Spacer size={2} />
              <NormalText className="bold">{__('Items Ordered')}</NormalText>
              <OrderContent basketInstance={basketInstance} type="orderHistory" />
              <AmountPaid order={order} cards={cards} />
            </Box>
          </>
        )}
      </>
    );
  };

  noOrder = () => (
    <IonItem lines="none">
      <div tabIndex="-1" className="sectiontitle" style={{ width: '100vh', textAlign: 'center' }}>
        {this.props.__('No order')}
      </div>
    </IonItem>
  );

  cancelOrder = () => {
    const { orderId, isCancel, restaurantId } = this.state;
    if (isCancel) {
      this.props.dispatch(checkCancelOrder(orderId, restaurantId));
      this.setState({ orderId: null, isCancel: false, restaurantId: null });
    }
  };

  handleCancelOrderModal = (flag) => {
    this.props.dispatch(setOrdersProp('cancelOrderModal', flag));
  };

  render() {
    const { __, location } = this.props;
    const order = location.state.order;
    const basketInstance = createNewBasketInstance();
    basketInstance.recreateOrder(order);
    const orderCompletePage = false;
    return (
      <Loading>
        <Layout headerTitle={__('Order Completed')} backHandler={this.backHandler}>
          <div className="absolute-content order-details">
            <div className="scrollable-y">
              {order ? this.drawOrder(order, orderCompletePage) : this.noOrder()}
            </div>
          </div>
        </Layout>
        <IonAlert
          isOpen={this.props.cancelOrderModal === true}
          onDidDismiss={() => this.handleCancelOrderModal(false)}
          header={__('Confirm')}
          message={__('Do you want to cancel this order?')}
          buttons={[
            {
              text: __('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: __('Remove'),
              handler: () => this.cancelOrder(),
            },
          ]}
        />
      </Loading>
    );
  }
}

const mapStateToProps = (store) => {
  const orders = store.orders;
  const { cancelOrderModal } = orders;
  const { restaurants } = store.restaurants;
  let lastOrder = null;
  const orderHistory = orders.orderHistory;
  if (orderHistory && orderHistory.length > 0) {
    lastOrder = orderHistory[0];
  }
  return {
    lastOrder,
    cards: orders.cards || [],
    restaurants: restaurants || [],
    cancelOrderModal,
  };
};

export default connect(mapStateToProps)(withRouter(withTranslation(HistoryDetails)));
