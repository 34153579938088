import { getEnv } from './lib/env.js';

let appConfig = {
  theme: {
    nav: {
      label: 'Kauai',
    },
    showHeaderOnAuthRoutes: true,
    showInputIconsOnLogin: false,
    routesWithoutStaticHeader: ['/item-details'],
  },
  services: {
    google_maps: {
      google_api_key: getEnv('GOOGLE_API_KEY'),
    },
  },
  api: {
    baseURL: getEnv('API_URL'),
    wordPressBaseURL: 'https://hybridapp.co.uk',
  },
  general: {
    clientName: 'Kauai',
    appExitRoutes: ['/', '/home', '/dashboard'],
    authRoutes: ['/login', '/register', '/reset-password'],
    routesWithBackButton: [
      '/cards',
      '/card-add',
      '/allergen-information',
      '/order',
      '/order-summary',
      '/order-completed',
      '/apply-vouchers',
      '/terms',
      '/privacy',
      '/faq',
      '/contact-details',
      '/click-and-collect',
      '/order-to-table',
      '/history-details',
      '/apply-points',
      '/pick-up-point',
      '/pick-up-point-check',
      '/delivery-time',
    ],
    isReduxDevToolsOn: true,
    isWebPlatform: false,
    defaultServiceCharge: 0,
  },
  appType: {
    hasOrdering: true,
    hasLoyalty: true,
    hasEmailValidationEnabled: true,
    hasCampaignManager: true,
    referAFriend: true,
    hasCancelOrder: true,
    hasServiceCharge: false,
    hasUnlockRewardsOnLoyalty: false,
    isReward: false,
    oldLayoutConfirmationScreens: false,
    hasPointsRedeem: true,
  },
  configS3: {
    imageNamePrefix: 'hyfive_profile_image_',
    bucketName: 'hybrid-apps-profile-images',
    region: 'eu-west-1',
    accessKeyId: getEnv('S3_ACCESS_KEY_ID'),
    secretAccessKey: getEnv('S3_SECRET_ACCESS_KEY'),
  },
  firebaseConfig: {
    apiKey: getEnv('FIREBASE_API_KEY'),
    authDomain: getEnv('FIREBASE_AUTH_DOMAIN'),
    databaseURL: getEnv('FIREBASE_DATABASE_URL'),
    projectId: getEnv('FIREBASE_PROJECT_ID'),
    storageBucket: getEnv('FIREBASE_STORAGE_BUCKET'),
    messagingSenderId: getEnv('FIREBASE_MESSAGING_SENDER_ID'),
    appId: getEnv('FIREBASE_APP_ID'),
    measurementId: getEnv('FIREBASE_MEASUREMENT_ID'),
  },
  payment: 'stripe',
};

export const updateConfig = (newConfig) => (appConfig = newConfig);

export const getConfig = () => appConfig;

export default appConfig;
