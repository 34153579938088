import React from 'react';
import './index.css';

export default class VoucherBorder extends React.Component {
  render() {
    return (
      <>
        <div className="left-circles first-circle"></div>
        <div className="left-circles second-circle"></div>
        <div className="left-circles third-circle"></div>
        <div className="left-circles fourth-circle"></div>
        <div className="left-circles fifth-circle"></div>
        <div className="right-circles first-circle"></div>
        <div className="right-circles second-circle"></div>
        <div className="right-circles third-circle"></div>
        <div className="right-circles fourth-circle"></div>
        <div className="right-circles fifth-circle"></div>
      </>
    );
  }
}
